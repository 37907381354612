import { appCodes, httpCodes } from "@rtbhouse-apps/commonlib/dist/consts";
import { UnauthorizedResponseError } from "@rtbhouse-apps/logger/dist/loggers/ApiBatchLogger/ApiBatchLogger";
import { ErrorResponseException } from "@rtbhouse-apps/rest-api-client";
import PropTypes from "prop-types";
import { Component } from "react";

import errorLogger from "lib/errorLogger";
import {
  ApiError,
  ApiErrorForbidden,
  ApiErrorNotFound,
  ApiErrorTooManyRequests,
  AuthError,
  UnexpectedError,
} from "lib/errors";
import { Redirect } from "lib/reactRouter";

import FatalErrorWindow from "./error-window/FatalErrorWindow";

function rejectionReasonToError(reason) {
  if (
    [appCodes.NO_AUTH_SESSION, appCodes.INVALID_CREDENTIALS].includes(reason.appCode) ||
    reason instanceof UnauthorizedResponseError
  ) {
    return new AuthError();
  }
  if (reason instanceof ErrorResponseException) {
    const { httpCode } = reason;
    return (
      {
        [httpCodes.FORBIDDEN]: new ApiErrorForbidden(),
        [httpCodes.NOT_FOUND]: new ApiErrorNotFound(),
        [httpCodes.TOO_MANY_REQUESTS]: new ApiErrorTooManyRequests(),
      }[httpCode] || new ApiError()
    );
  }
  return new UnexpectedError();
}

function reportApiRejection(reason) {
  const reportOnlyCodes = [
    httpCodes.INTERNAL_ERROR,
    httpCodes.BAD_GATEWAY,
    httpCodes.GATEWAY_TIMEOUT,
    httpCodes.SERVICE_UNAVAILABLE,
    httpCodes.FORBIDDEN,
    httpCodes.NOT_FOUND,
    httpCodes.METHOD_NOT_ALLOWED,
  ];

  const ignoreAppCodes = [appCodes.ENTITY_NOT_FOUND];

  if (reportOnlyCodes.includes(reason.httpCode) && !ignoreAppCodes.includes(reason.appCode)) {
    errorLogger.logMessage("Ajax error", { extra: reason });
  }
}

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
    this.registerGlobalErrorHandlers();
  }

  componentDidCatch(error, errorInfo) {
    error.componentStack = errorInfo.componentStack;
    errorLogger.logException(error);
    this.setErrorOnlyOnce(error);
  }

  setErrorOnlyOnce(error) {
    this.setState((state) => ({ error: state.error || error }));
  }

  registerGlobalErrorHandlers() {
    window.onerror = (message, source, lineno, colno, error) => {
      // Safari sees errors from the same origin as cross origin ones and log them as "Script error."
      // What makes impossible to filter "ResizeObserver loop" error.
      if (/^Script error/.test(message)) {
        // eslint-disable-next-line no-console
        console.warn(message);
        return;
      }

      if (/^ResizeObserver loop/.test(message)) {
        // eslint-disable-next-line no-console
        console.warn(message);
        return;
      }

      /**
       * Postpone handling global error due to rethrown in dev env:
       * https://github.com/facebook/react/issues/12897#issuecomment-410036991
       * to catch it first by react boundary (like in prod env).
       */
      setTimeout(() => {
        this.setErrorOnlyOnce(error || new UnexpectedError(message));
      }, 0);
    };

    window.onunhandledrejection = ({ reason }) => {
      this.unhandledRejectionHandler(reason);
    };

    window.addEventListener("beforeunload", () => {
      window.onunhandledrejection = null;
    });
  }

  unhandledRejectionHandler(reason) {
    const error = rejectionReasonToError(reason);
    if (error instanceof ApiError) {
      reportApiRejection(reason);
    } else {
      errorLogger.logException(reason);
    }
    this.setErrorOnlyOnce(error);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      if (error instanceof AuthError) {
        return [
          <Redirect push to="/auth/logout" key="auth-error-redirect" />,
          // children needed for proper routing
          children,
        ];
      }

      return <FatalErrorWindow error={error} />;
    }

    return children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppErrorBoundary;
