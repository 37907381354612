import { logger } from "@rtbhouse-apps/logger-react";
import $ from "jquery";
import KendoCore from "kendo-ui-core/js/kendo.core";
import _ from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import ReactDOM from "react-dom";

import "kendo-ui-core/js/kendo.window";

const defaults = {
  config: {
    visible: false,
    modal: true,
    width: "600px",
  },
};

/**
 * IMPORTANT!
 * You should probably always use 'appendTo' option to prevent window from moving anywhere
 * (just move it to the same place), otherwise React "Invariant Violation" may appear
 * (react doesn't expect content to move or disappear)
 */
class KendoWindow extends Component {
  el = null;

  widget = null;

  componentDidMount() {
    const { onWidgetReady } = this.props;
    this.el = $(ReactDOM.findDOMNode(this));
    this.widget = this.initWidget(this.props);
    if (onWidgetReady) {
      onWidgetReady(this.widget);
    }
  }

  componentDidUpdate(prevProps) {
    const { config } = this.props;
    if (!_.isEqual(config, prevProps.config)) {
      this.widget.setOptions(config);
    }
  }

  componentWillUnmount() {
    KendoCore.destroy(this.el);
  }

  initWidget(props) {
    const { className, config } = this.props;

    $(this.el).kendoWindow({
      open: () => {
        const isHelpModal = config?.appendTo === "#help-window";
        if (!isHelpModal && config?.id) logger.modal(this.props.config.id);
      },
      ...defaults.config,
      ...props.config,
    });

    const widget = this.el.data("kendoWindow");
    if (className) {
      widget.wrapper.addClass(className);
    }

    return widget;
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

KendoWindow.defaultProps = {
  config: {},
  onWidgetReady: null,
};

KendoWindow.propTypes = {
  config: PropTypes.object,
  onWidgetReady: PropTypes.func,
};

export default KendoWindow;
