import { decorateWithEventDispatcher } from "@rtbhouse-apps/reactlib/dist/decorateWithEventDispatcher";
import $ from "jquery";
import KendoCore from "kendo-ui-core/js/kendo.core";
import PropTypes from "prop-types";
import { Component } from "react";
import ReactDOM from "react-dom";

import { sseEvents } from "lib/consts";

import "kendo-ui-core/js/kendo.notification";

const defaults = {
  config: {
    position: {
      top: 10,
      right: 10,
    },
    stacking: "down",
    width: 300,
  },
};

class KendoNotification extends Component {
  el = null;

  widget = null;

  componentDidMount() {
    const { addListener } = this.props;
    this.el = $(ReactDOM.findDOMNode(this));
    this.widget = this.initWidget(this.props);
    addListener(sseEvents.SHOW_NOTIFICATION, this.handleShowNotification.bind(this));
  }

  componentWillUnmount() {
    KendoCore.destroy(this.el);
  }

  handleShowNotification(e) {
    this.widget.show.call(this.widget, ...e.detail);
  }

  initWidget(props) {
    const config = { ...defaults.config, ...props.config };
    this.el.kendoNotification(config);
    return this.el.data("kendoNotification");
  }

  render() {
    return <div />;
  }
}

KendoNotification.defaultProps = {
  config: {},
  addListener: null,
};

KendoNotification.propTypes = {
  config: PropTypes.object,
  addListener: PropTypes.func,
};

export default decorateWithEventDispatcher(KendoNotification);
