import Loader from "./Loader";

import "./ComponentLoader.scss";

const ComponentLoader = ({ isLoading, width, height, children }) => {
  const opacity = isLoading ? 0.4 : 1;
  const styles = {};
  if (width) {
    styles.width = `${width}px`;
  }
  if (height) {
    styles.height = `${height}px`;
  }

  return (
    <div style={styles}>
      <div className="component-loader">
        {isLoading ? <Loader type="line-scale" /> : null}

        <div style={{ opacity }}>{children || <div className="placeholder" />}</div>
      </div>
    </div>
  );
};

export default ComponentLoader;
