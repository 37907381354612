// eslint-disable-next-line simple-import-sort/imports
import "normalize.css/normalize.css"; // If this import is after 'import App from "components/app/App";' then the table on 'RtbConversionsPage' doesn't display correctly.

import { ERRORS, logger } from "@rtbhouse-apps/logger-react";
import { createRoot } from "react-dom/client";

import App from "components/app/App";
import errorLogger from "lib/errorLogger";
import systemRepo from "repos/SystemRepo";
import { RuntimeConfig } from "services";
import systemActions from "services/actions/SystemActions";

import "styles/all.scss";

function initErrorLogger(config: RuntimeConfig) {
  if (config.sentryEnabled) {
    errorLogger.init({
      dsn: config.sentryDsn,
      environment: config.environment,
      release: config.version,
      ignoreErrors: ["Network request failed", "Failed to fetch", "NetworkError"],
      denyUrls: [/^chrome:\/\//i],
    });
  }
}

export function initGuiLogger(config: RuntimeConfig) {
  if (config.guiLoggerEnabled && config.environment !== "e2e") {
    logger.init({
      appName: "panel-reports",
      host: config.guiLoggerBaseUrl,
      localStorageKey: "@rtbhouse/logger:Reports",
      debug: config.guiLoggerDebugEnabled,
      environment: config.environment,
      dryRun: config.guiLoggerDryRunEnabled,
      // In case of any warning, send it to the Sentry.
      onWarning: (event, warningTitle, warningMessage) =>
        errorLogger.logWarning(`[GUI Logger] ${warningTitle} - ${warningMessage}. Event "${event}"`),
      onException: (error) => {
        if (error instanceof Error && error.message === ERRORS.unauthorizedResponse) {
          window.location.reload();
          return;
        }
        // In case of any other error, send it to the Sentry.
        errorLogger.logException(error);
      },
    });
  }
}

async function checkApi() {
  const appContainer = document.getElementById("app-container");
  if (!appContainer) {
    return; // adblocking software detected
  }
  try {
    await systemRepo.getHealthcheck();
    const root = createRoot(appContainer);
    root.render(<App />);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    const root = createRoot(appContainer);
    root.render(
      <div className="error-message-container">
        <h1>Maintenance break</h1>
        <p>This page is under maintenance.</p>
        <p>Please, try again later</p>
      </div>,
    );
  }
}

async function renderApp() {
  const config = await systemActions.fetchConfig();
  initErrorLogger(config);
  initGuiLogger(config);

  checkApi();
}

renderApp();
